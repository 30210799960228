import React from "react";
import { Link, graphql } from "gatsby";

import { BlogContainer } from "../../components";

const BlogPost = ({ data }) => {
  const post = data.microcmsBlog;
  const genre = post.genre[0].genre;
  return (
    <BlogContainer>
      <div className="bg-gray-200 overflow-x-scroll mb-8 px-6 md:mb-16 md:mx-auto lg:mb-24 lg:px-0">
        <div className="whitespace-nowrap flex items-center h-12 md:mx-auto md:max-w-3xl xl:max-w-5xl">
          <Link
            className="text-sm font-medium text-gray-500 hover:text-tkc-red"
            to="/"
          >
            Home
          </Link>
          <span className="mx-2 text-sm font-medium text-gray-500">・</span>
          <Link
            to="/blog"
            className="text-sm font-medium text-gray-500 hover:text-tkc-red"
          >
            Blog
          </Link>
          <span className="mx-2 text-sm font-medium text-tkc-red">・</span>
          <Link to={`/blog/${post.blogId}`} className="text-sm font-medium">
            {post.title}
          </Link>
        </div>
      </div>
      <div className="mb-8 px-6 md:mb-16 md:mx-auto md:max-w-xl lg:mb-24 lg:px-0 xl:max-w-3xl">
        <div className="mb-6">
          <span className="px-4 pt-2 pb-1 capitalize text-white text-sm bg-tkc-red rounded-full">
            {genre}
          </span>
        </div>
        <h1 className="mb-2 block text-lg font-bold md:text-3xl md:leading-normal">
          {post.title}
        </h1>
        <time
          className="text-gray-400 text-sm tracking-wide"
          datetime={post.date}
        >
          {post.date}
        </time>
      </div>
      <div className="mb-8 md:mx-auto md:px-6 md:max-w-3xl md:mb-16 xl:max-w-5xl">
        <div className="bg-white md:rounded-lg">
          <div className="flex flex-col items-center justify-center mb-6 h-72 md:h-96 xl:h-128">
            <img
              className="w-full h-full object-cover md:rounded-t-lg"
              src={post.featureImage.url}
              alt={`${post.title} 画像`}
            />
          </div>
          {/* メッセージ本文 */}
          <div className="flex flex-col mx-auto md:max-w-2xl xl:max-w-4xl">
            <div className="pb-4 px-6 md:px-12 lg:px-20 xl:pb-12">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${post.body}`,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center mb-12 lg:mb-24">
        <div className="flex items-center justify-center">
          <Link className="mx-16 md:mx-32" to="/blog">
            <svg
              className="w-6 text-tkc-red fill-current"
              viewBox="0 0 16 16"
              fill="current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M14 16C12.8954 16 12 15.1046 12 14C12 12.8954 12.8954 12 14 12C15.1046 12 16 12.8954 16 14C16 15.1046 15.1046 16 14 16ZM8 16C6.89543 16 6 15.1046 6 14C6 12.8954 6.89543 12 8 12C9.10457 12 10 12.8954 10 14C10 15.1046 9.10457 16 8 16ZM2 16C0.89543 16 0 15.1046 0 14C0 12.8954 0.89543 12 2 12C3.10457 12 4 12.8954 4 14C4 15.1046 3.10457 16 2 16ZM14 10C12.8954 10 12 9.10457 12 8C12 6.89543 12.8954 6 14 6C15.1046 6 16 6.89543 16 8C16 8.53043 15.7893 9.03914 15.4142 9.41421C15.0391 9.78929 14.5304 10 14 10ZM8 10C6.89543 10 6 9.10457 6 8C6 6.89543 6.89543 6 8 6C9.10457 6 10 6.89543 10 8C10 8.53043 9.78929 9.03914 9.41421 9.41421C9.03914 9.78929 8.53043 10 8 10ZM2 10C0.89543 10 0 9.10457 0 8C0 6.89543 0.89543 6 2 6C3.10457 6 4 6.89543 4 8C4 8.53043 3.78929 9.03914 3.41421 9.41421C3.03914 9.78929 2.53043 10 2 10ZM14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0C15.1046 0 16 0.89543 16 2C16 2.53043 15.7893 3.03914 15.4142 3.41421C15.0391 3.78929 14.5304 4 14 4ZM8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0C9.10457 0 10 0.89543 10 2C10 2.53043 9.78929 3.03914 9.41421 3.41421C9.03914 3.78929 8.53043 4 8 4ZM2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0C3.10457 0 4 0.89543 4 2C4 2.53043 3.78929 3.03914 3.41421 3.41421C3.03914 3.78929 2.53043 4 2 4Z" />
            </svg>
          </Link>
        </div>
      </div>
    </BlogContainer>
  );
};

export default BlogPost;

export const query = graphql`
  query ($id: String!) {
    microcmsBlog(id: { eq: $id }) {
      blogId
      title
      date(formatString: "Y-MM-DD")
      featureImage {
        url
      }
      category {
        id
      }
      genre {
        id
        genre
      }
      body
    }
  }
`;
